img {
  width: 100%;
  pointer-events: none;
  vertical-align: middle;
}
#adoraboo-wrapper {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  color: #fff;
  box-sizing: border-box;
}

#adoraboo-wrapper .download-ios {
  background: url(./images/download-ios.png) no-repeat center/contain;
}

#adoraboo-wrapper .download-android {
  background: url(./images/google-play.png) no-repeat center/contain;
}

a {
  text-decoration: underline;
}

footer .link-wrapper a {
  color: #408817;
}
footer .link-wrapper .split {
  visibility: visible;
  color: #408817;
}

/* H5 */
@media screen and (max-width: 900px) {
  #adoraboo-wrapper {
    background: url(./images/bg-h5.jpg) no-repeat center/cover;
    padding-top: 2em;
  }

  .logo {
    margin: auto;
    width: 55%;
  }
  .slogen_img {
    width: 300px;
    margin: 1.8em auto;
  }
  .slogen {
    width: 90%;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 2.5px 0px #154D76;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #154D76;
    font-family: "Baloo Bhaijaan";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 35.2px */
    margin: 1.5em auto;
  }
  .download-ios {
    width: 213px;
    height: 70px;
    margin: 2.5em auto;
  }

  .download-android {
    width: 213px;
    height: 70px;
    margin: 2.5em auto;
  }

  footer {
    color: #FFF;
    text-align: center;
    line-height: 2em;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  footer .email {
    color: #FFFFFF;
    margin-left: 2em;
  }

  footer .link-policy {
    margin-right: 1em;
  }
  footer .link-terms {
    margin-left: 1em;
  }
}

/* PC */
@media screen and (orientation: landscape) and (min-width: 900px) {
  #adoraboo-wrapper {
    min-width: 980px;
    background: url(./images/bg-pc.jpg) no-repeat bottom/cover;
    color: #fff;
    position: relative;
  }
  .logo {
    width: 260px;
    position: absolute;
    top: 40px;
    left: 60px;
  }
  .slogen_img {
    width: 480px;
    align-self: center;
    position: absolute;
    left: calc((100% - 480PX) / 2);
    top: 22%;
  }
  .slogen {
    width: 65%;
    position: absolute;
    height: 124px;
    left: 18%;
    top: 22%;
    font-family: 'Baloo Bhaijaan';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #154D76;
    text-shadow: 0px 2.5px 0px #154D76;
  }
  .download-ios {
    width: 213px;
    height: 70px;
    position: absolute;
    top: 42%;
    left: calc((100% - 213px * 2 - 30px) / 2);
    cursor: pointer;
  }
  .download-android {
    width: 213px;
    height: 70px;
    position: absolute;
    top: 42%;
    right: calc((100% - 213px * 2 - 30px) / 2);
    cursor: pointer;
  }

  footer {
    color: #FFFFFF;
    text-align: center;
    line-height: 2em;
    font-size: 0.82rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 50px;
    background-color: #89C668;
    align-content: center;
  }

  footer .copyright {
    display: inline-block;
  }
  footer .email {
    color: #FFFFFF;
    margin-left: 2em;
  }
  footer .link-wrapper {
    display: inline-block;
    margin-left: 2em;
  }
  footer .link-wrapper .link-policy {
    margin-right: 1em;
  }
  footer .link-wrapper .link-terms {
    margin-left: 1em;
  }
}